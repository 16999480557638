import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";

import { CollapsibleTableColumn, CollapsibleTableHeader } from "./types";

const CollapsibleRow = ({
  columns,
  subHeaders,
  subColumns,
}: {
  columns: CollapsibleTableColumn[];
  subHeaders?: CollapsibleTableHeader[];
  subColumns?: CollapsibleTableColumn[];
}) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  return (
    <>
      <TableRow
        sx={{
          "& td:first-of-type": {
            paddingLeft: 0.5,
          },
          "& > .MuiTableCell-root": {
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingY: {
              xs: 2,
              sm: 1,
            },
            paddingX: {
              xs: 0,
              sm: 1,
            },
            borderTop: `1px solid ${theme.palette.divider}`,
          },
        }}
      >
        {columns.map((column) => (
          <TableCell
            key={column.name}
            sx={{
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            {column.content}
          </TableCell>
        ))}
        {!!subColumns?.length && !!subHeaders?.length && (
          <TableCell sx={{ textAlign: { xs: "center", sm: "right" } }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {!!subColumns?.length && !!subHeaders?.length && (
        <TableRow>
          <TableCell
            padding="none"
            colSpan={columns.length + 2}
            sx={{ border: "unset" }}
          >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Paper
                sx={{
                  overflow: "auto",
                  marginBottom: 1,
                }}
                elevation={3}
              >
                <Table
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    "& th:last-child": {
                      paddingRight: 25,
                    },
                  }}
                >
                  <TableHead sx={{ backgroundColor: "grey.200" }}>
                    <TableRow>
                      {subHeaders?.map((header) => (
                        <TableCell key={header.name}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {subColumns?.map((column) => (
                        <TableCell key={column.name}>
                          {column.content}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default CollapsibleRow;
