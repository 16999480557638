import React, { useRef } from "react";

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Order_By } from "../../generated/graphql";
import LoadingSkeleton from "../LoadingSkeleton";

import CollapsibleRow from "./CollapsibleRow";
import MobileSort from "./MobileSort";
import SortableHeaderCell from "./SortableHeaderCell";
import {
  CollapsibleTableColumn,
  CollapsibleTableData,
  CollapsibleTableHeader,
  CollapsibleTableProps,
  CommonTableType,
} from "./types";

const CollapsibleTable = <T extends CommonTableType>({
  items,
  title,
  firstAction,
  firstActionResult,
  secondAction,
  thirdAction,
  loading,
  mobileData,
  tabletData,
  desktopData,
  orderBy,
  setOrderBy,
  filterItems,
  columnVisibilityModel = {},
}: CollapsibleTableProps<T>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const hasActions = firstAction || secondAction || thirdAction || filterItems;

  const handleUpdateSort = (
    header: CollapsibleTableHeader,
    sortDirection?: Order_By
  ) => {
    setOrderBy?.({ field: header.name, direction: sortDirection });
  };

  const getColumnData = (data: CollapsibleTableData<T> | undefined) => {
    return data ?? { columns: () => [], subHeaders: [], subColumns: () => [] };
  };

  const {
    columns: mobileColumns,
    subHeaders: mobileSubHeaders,
    subColumns: mobileSubColumns,
  } = getColumnData(mobileData);

  const {
    headers: tabletHeaders,
    columns: tabletColumns,
    subHeaders: tabletSubHeaders,
    subColumns: tabletSubColumns,
  } = getColumnData(tabletData);

  const {
    headers: desktopHeaders,
    columns: desktopColumns,
    subHeaders: desktopSubHeaders,
    subColumns: desktopSubColumns,
  } = desktopData;

  const renderHeaders = (headers?: CollapsibleTableHeader[]) =>
    headers?.map(
      (header, idx) =>
        columnVisibilityModel[header.name] !== false && (
          <SortableHeaderCell
            key={header.name + idx}
            header={header}
            sortField={orderBy?.field ?? ""}
            sortDirection={orderBy?.direction}
            handleUpdateSort={handleUpdateSort}
          />
        )
    );

  const renderRows = (
    item: T,
    columns: (item: T) => CollapsibleTableColumn[],
    subHeaders?: CollapsibleTableHeader[],
    subColumns?: (item: T) => CollapsibleTableColumn[]
  ) => {
    return (
      <CollapsibleRow
        key={`collapsibleRow-${item.id}`}
        columns={columns(item).filter(
          (column) => columnVisibilityModel[column.name] !== false
        )}
        subHeaders={subHeaders?.filter(
          (column) => columnVisibilityModel[column.name] !== false
        )}
        subColumns={subColumns?.(item).filter(
          (column) => columnVisibilityModel[column.name] !== false
        )}
      />
    );
  };

  const renderActions = () => (
    <Stack my={2} direction="row" display="flex" justifyContent="space-between">
      {(firstAction || secondAction) && (
        <Stack
          display="flex"
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          sx={{ overflowX: "auto" }}
          flexGrow={1}
          gap={thirdAction || filterItems ? 1 : 0}
          mr={thirdAction || filterItems ? 1 : 0}
        >
          {firstAction && (
            <Box order={{ xs: secondAction ? 2 : 1, sm: 1 }}>{firstAction}</Box>
          )}
          {secondAction && <Box order={{ xs: 1, sm: 2 }}>{secondAction}</Box>}
        </Stack>
      )}
      <Stack
        display="flex"
        direction={{ xs: "column", sm: "row" }}
        gap={1}
        width={hasActions ? "auto" : "100%"}
      >
        {thirdAction && (
          <Box order={{ xs: 2, sm: 0 }} textAlign="end">
            {thirdAction}
          </Box>
        )}
        <Stack
          display="flex"
          direction="row"
          gap={1}
          alignSelf="end"
          sx={{ marginLeft: hasActions ? 0 : "auto" }}
        >
          {filterItems && (
            // filter component; TODO SER-4326
            <></>
          )}
          <MobileSort
            columns={getColumnsForSort(
              xs,
              sm,
              md,
              desktopHeaders,
              desktopSubHeaders,
              tabletSubHeaders
            )}
            sortField={orderBy?.field ?? ""}
            sortDirection={orderBy?.direction}
            handleUpdateSort={handleUpdateSort}
          />
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <>
      {loading && <LoadingSkeleton />}
      {!loading && items?.length === 0 && hasActions && (
        <>
          <Box>{renderActions()}</Box>
          <Box sx={{ overflowX: "auto" }}>{firstActionResult}</Box>
        </>
      )}
      {items?.length !== undefined && items?.length > 0 && (
        <Box>
          {title && (
            <Stack>
              <Box alignContent="center" mr={1}>
                <Typography variant="subtitle1">{title}</Typography>
              </Box>
            </Stack>
          )}
          {renderActions()}
          <Box sx={{ overflowX: "auto" }}>{firstActionResult}</Box>
          <TableContainer ref={tableContainerRef}>
            <Table
              aria-label="collapsible table"
              sx={{
                borderCollapse: "collapse",
                borderSpacing: "0px 8px",
              }}
            >
              <TableHead>
                <TableRow>
                  {lg && renderHeaders(desktopHeaders)}
                  {(sm || md) && (
                    <>
                      {renderHeaders(tabletHeaders)}
                      {!!tabletSubColumns?.length &&
                        !!tabletSubHeaders?.length && <TableCell />}
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map((item) => (
                  <React.Fragment key={`tableBody-rows-${item.id}`}>
                    {lg &&
                      renderRows(
                        item,
                        desktopColumns,
                        desktopSubHeaders,
                        desktopSubColumns
                      )}
                    {(sm || md) &&
                      renderRows(
                        item,
                        tabletColumns,
                        tabletSubHeaders,
                        tabletSubColumns
                      )}
                    {xs &&
                      renderRows(
                        item,
                        mobileColumns,
                        mobileSubHeaders,
                        mobileSubColumns
                      )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

const getColumnsForSort = (
  xs: boolean,
  sm: boolean,
  md: boolean,
  desktopHeaders: CollapsibleTableHeader[] | undefined,
  desktopSubHeaders: CollapsibleTableHeader[] | undefined,
  tabletSubHeaders: CollapsibleTableHeader[] | undefined
) => {
  const sortableColumns = desktopHeaders?.filter((header) => header.sortable);
  const sortableSubColumns = desktopSubHeaders?.filter(
    (header) => header.sortable
  );
  const sortableTabletSubColumns = tabletSubHeaders?.filter(
    (header) => header.sortable
  );
  if (xs) {
    return [...(sortableColumns ?? []), ...(sortableSubColumns ?? [])];
  } else if (sm || md) {
    return sortableTabletSubColumns;
  }
  return sortableSubColumns;
};

export default CollapsibleTable;
